import { put, takeLatest, select } from 'redux-saga/effects';
import {
  getVenue,
  searchVenues,
  searchVenuesQuery,
  getVenueBySlug,
} from '../../graphql/customQueries';
import filterResults from '../../utils/filterResults';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './VenuesAction';
import * as TYPES from './VenuesTypes';
import { getConfig, getUser } from '../selectors';
import { VENUE_TYPES } from '../../utils/constants/FilterTypes';
import { VENUE_SORT_DIRECTIONS } from '../../utils/constants/VenueSortDirection';
import {
  filterUnavailableRelatedEvents,
  isPastEvent,
  isVenueAvailable,
} from '../../utils/eventHelpers';
import { clearPermalinkRouteRedirect } from '../../utils/sessionStorageHelper';

function* callSearchVenuesQuery(payload) {
  try {
    const { keyword, marketId, filters } = payload;
    const user = yield select(getUser);
    const isTestUser = user.user.isTest;
    const result = yield callGraphqlWithToken({
      query: searchVenuesQuery,
      variables: {
        input: { keyword, marketId },
        filter: {
          isActive: { eq: true },
          isHidden: { eq: false },
          ...(filters.venueType.length === 1 && {
            venueType: { eq: filters.venueType[0].venueType },
          }),
          ...(!isTestUser && { isTest: { ne: true } }),
        },
      },
    });

    return result.data.searchVenuesQuery.items;
  } catch (e) {
    console.error(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionSearchVenuesFail(e));
  }
}

export function* findVenuesRequestHandler(data) {
  try {
    if (data.payload.keyword) {
      yield put(ACTIONS.actionSearchVenuesRequest(data.payload));
    } else {
      yield put(ACTIONS.actionVenuesByMarketRequest(data.payload));
    }
  } catch (e) {
    console.error(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionFindVenuesFail(e));
  }
}

export function* getVenueRequestHandler(data) {
  try {
    const result = yield callGraphqlWithToken({
      query: getVenue,
      variables: { id: data.payload },
    });
    if (result.data.getVenue === null) {
      throw new Error('No venue');
    }
    const venue = filterUnavailableRelatedEvents(result.data.getVenue);
    yield put(ACTIONS.actionGetVenueSuccess(venue));
  } catch (e) {
    console.error(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionGetVenueFail(e));
  }
}
export function* searchEventsRequestHandler(data) {
  try {
    const config = yield select(getConfig);
    const isTestUser = data.payload?.isTestUser;
    const filters = {
      isHidden: {
        eq: false,
      },
      isActive: {
        eq: true,
      },
      venueType: {
        eq: VENUE_TYPES.EVENT,
      },
      ...(!isTestUser && {
        isTest: {
          eq: false,
        },
      }),
    };
    const sort = {
      field: 'sortOrder',
      direction:
        VENUE_SORT_DIRECTIONS[config.venueSortDirection] ||
        VENUE_SORT_DIRECTIONS.asc,
    };
    const result = yield callGraphqlWithToken({
      query: searchVenues,
      variables: {
        filter: filters,
        sort,
      },
    });
    yield put(
      ACTIONS.actionGetEventsSuccess(
        result.data.searchVenues?.items?.filter((venue) => !isPastEvent(venue))
      )
    );
  } catch (e) {
    console.error(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionGetEventsFail(e));
  }
}
export function* searchVenuesRequestHandler(data) {
  try {
    let venues = yield callSearchVenuesQuery(data.payload);
    let { marketId } = data.payload;
    const filteredVenues = filterResults(data.payload.filters, venues);

    if (marketId != null && filteredVenues.length === 0) {
      marketId = null;
      venues = yield callSearchVenuesQuery({ ...data.payload, marketId });
    }

    yield put(
      ACTIONS.actionSetVenues(filterResults(data.payload.filters, venues))
    );
    yield put(
      ACTIONS.actionSearchVenuesSuccess({
        inLocalMarket: marketId != null,
      })
    );
  } catch (e) {
    console.error(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionSearchVenuesFail(e));
  }
}

export function* venuesByMarketRequestHandler(data) {
  try {
    const venues = [];
    const user = yield select(getUser);
    const config = yield select(getConfig);
    const isTestUser = user.user.isTest;
    let nextToken;

    do {
      const result = yield callGraphqlWithToken({
        query: searchVenues,
        variables: {
          filter: {
            isActive: { eq: true },
            marketId: { eq: data.payload.marketId },
            isHidden: { eq: false },
            ...(data.payload.filters.venueType?.length === 1 && {
              venueType: {
                eq: data.payload.filters.venueType[0].venueType,
              },
            }),
            ...(!isTestUser && { isTest: { ne: true } }),
          },
          sort: {
            direction:
              VENUE_SORT_DIRECTIONS[config.venueSortDirection] ||
              VENUE_SORT_DIRECTIONS.asc,
            field: 'sortOrder',
          },
          nextToken,
        },
      });

      result.data.searchVenues.items = result.data.searchVenues?.items?.filter(
        (venue) => isVenueAvailable(venue)
      );

      venues.push(...result.data.searchVenues.items);
      nextToken = result.data.searchVenues.nextToken;
    } while (nextToken != null);

    yield put(
      ACTIONS.actionSetVenues(filterResults(data.payload.filters, venues))
    );
    yield put(ACTIONS.actionVenuesByMarketSuccess());
  } catch (e) {
    console.error(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionVenuesByMarketFail(e));
  }
}

export function* getVenueBySlugRequestHandler(data) {
  try {
    clearPermalinkRouteRedirect();
    const result = yield callGraphqlWithToken({
      query: getVenueBySlug,
      variables: {
        venueSlug: data.payload,
        filter: {
          isActive: { eq: true },
        }
      }, //Update with index in dynamoDB
    });
    let venue = result?.data?.venuesByVenueSlug?.items[0];
    let venueId = venue?.id;
    const { permaLinks } = yield select(getConfig);
    let pathname = '/';
    if (venueId) {
      pathname = `/venue/${venueId}`;
    } else if (permaLinks[data.payload]) {
      pathname = permaLinks[data.payload];
    }
    yield put(ACTIONS.actionGetVenueBySlugSuccess(pathname));
    yield put(ACTIONS.actionGetVenueSuccess(venue));
  } catch (e) {
    console.error(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionGetVenueFail(e));
  }
}

export default function* venuesSaga() {
  yield takeLatest(TYPES.FIND_VENUES_REQUEST, findVenuesRequestHandler);
  yield takeLatest(
    TYPES.VENUES_BY_MARKET_REQUEST,
    venuesByMarketRequestHandler
  );
  yield takeLatest(TYPES.SEARCH_VENUES_REQUEST, searchVenuesRequestHandler);
  yield takeLatest(TYPES.GET_VENUE_REQUEST, getVenueRequestHandler);
  yield takeLatest(TYPES.GET_EVENTS_REQUEST, searchEventsRequestHandler);
  yield takeLatest(
    TYPES.GET_VENUE_BY_SLUG_REQUEST,
    getVenueBySlugRequestHandler
  );
}
