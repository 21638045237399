import palette from '../../../theme/palette';

const EVENT = {
  borderRadius: 100,
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: 0,
  paddingTop: 4,
  paddingBottom: 4,
  '& span': {
    padding: '0 8',
  },
};

const COLORS = Object.freeze({
  active: {
    color: palette.chip.active,
    backgroundColor: palette.chip.background,
    borderColor: palette.chip.background,
  },
  pending: {
    color: palette.digitalGray[110],
    backgroundColor: palette.digitalGray[10],
    borderColor: palette.digitalGray[10],
  },
  available: {
    color: palette.primary.text,
    backgroundColor: '#FFF1D0',
    borderColor: '#FFF1D0',
  },
  prepaid: {
    color: '#25810E',
    backgroundColor: '',
    borderColor: '#25810E',
  },
  paid: {
    color: '#1E6A0B',
    backgroundColor: '#DAE8D8',
    borderColor: '#DAE8D8',
  },
  canceled: {
    color: palette.red.text,
    backgroundColor: palette.red.light,
    borderColor: palette.red.light,
  },
  miles: {
    color: palette.chip.active,
    backgroundColor: palette.chip.background,
    borderColor: palette.chip.background,
  },
  event: {
    borderRadius: 100,
    color: '#008140',
    backgroundColor: palette.chip.event,
    borderColor: palette.chip.event,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0,
    paddingTop: 4,
    paddingBottom: 4,
    '& span': {
      padding: '0 8',
    },
  },
  soldout: {
    borderRadius: 100,
    color: palette.digitalGray[100],
    backgroundColor: palette.chip.soldout,
    borderColor: palette.chip.soldout,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0,
    paddingTop: 4,
    paddingBottom: 4,
    '& span': {
      padding: '0 8',
    },
  },
  isCapitalOneExclusive: {
    ...EVENT,
    backgroundColor: palette.background.white,
    color: palette.blue[50],
  },
  eventCard: {
    borderRadius: 4,
    border: 0,
    backgroundColor: palette.background.grayLight,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    color: '#6E6E6E',
    height: 20,
    '& span': {
      padding: '0 8',
    },
  },
  signatureCollection: {
    color: '#8F6400',
    background: '#FFF4D1',
    border: '1px solid #8F6400',
  },
  cardholderFavorite: {
    color: '#3D3D3D',
    background: palette.background.white,
    border: '1px solid #3D3D3D',
  },
  goingFast: {
    color: '#CC2427',
    background: palette.background.white,
    border: '1px solid #CC2427',
  },
  newRestaurant: {
    color: '#0070A8',
    background: palette.background.white,
    border: '1px solid #0070A8',
  },
  rareFind: {
    color: '#0070A8',
    background: palette.background.white,
    border: '1px solid #0070A8',
  },
  locationChip: {
    borderRadius: 4,
    border: 0,
    backgroundColor: palette.informationalBlue.emphasisLow,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    color: palette.spoon.blue[40],
    height: 20,
    '& span': {
      padding: '0 8',
    },
  },
  soldOutChip: {
    borderRadius: 4,
    border: 0,
    backgroundColor: palette.digitalGray[10],
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    color: palette.secondary[65],
    height: 20,
    '& span': {
      padding: '0 8',
    },
  },
});

export default COLORS;
