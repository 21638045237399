import React from 'react';
import Button from '../../components/overrides/Button';
import { CircularProgress } from '@material-ui/core';
import Typography from '../../components/overrides/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { PROGRESS_BAR_LABEL } from '../../assets/copy';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    height: 48,
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      width: 128,
    },
  },
  clickable: {
    color: theme.palette.primary,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.interactionBlue[60],
    },
    '&:active': {
      backgroundColor: theme.palette.interactionBlue[70],
    },
  },
  busy: {
    backgroundColor: theme.palette.interactionBlue[70],
    cursor: 'default',
    '&:hover, &:active': {
      backgroundColor: theme.palette.interactionBlue[70],
    },
  },
  circle: {
    color: theme.palette.text.secondary,
  },
}));

const ProgressButton = ({
  isBusy,
  onClick,
  text,
  disabled,
  classesOverride,
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <Button
      className={clsx(
        classes.button,
        isBusy ? classes.busy : classes.clickable
      )}
      disableRipple={isBusy}
      disableFocusRipple={isBusy}
      disabled={disabled}
      {...(!isBusy && { onClick })}
      {...(isBusy && { 'aria-label': PROGRESS_BAR_LABEL })}>
      {isBusy ? (
        <CircularProgress
          aria-label={PROGRESS_BAR_LABEL}
          className={classes.circle}
          size={32}
          role="progressbar"
          title={PROGRESS_BAR_LABEL}
        />
      ) : (
        <Typography color="secondary" variant="medium2Normal">
          {text}
        </Typography>
      )}
    </Button>
  );
};

export default ProgressButton;
