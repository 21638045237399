import * as cookie from 'cookie';

const getCookie = (cookieKey) => {
  const cookies = cookie.parse(document.cookie);
  return cookies[cookieKey];
};

const setCookie = (cookieKey, cookieValue, options) => {
  document.cookie = cookie.serialize(cookieKey, cookieValue, options);
};

const removeCookie = (cookieKey, options) => {
  document.cookie = cookie.serialize(cookieKey, null, {
    ...options,
    maxAge: 0,
  });
};

export { getCookie, setCookie, removeCookie };
