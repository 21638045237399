import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { BREAKPOINT_VALUES } from '../../utils/constants/Breakpoints';
import { useViewport } from '../../utils/useViewport';
import LightLogoSVG from '../../assets/icons/logo-white.svg';
import DarkLogoSVG from '../../assets/icons/logo-dark.svg';

export const TABLET_BREAKPOINT = BREAKPOINT_VALUES.xxl;
export const MOBILE_BREAKPOINT = BREAKPOINT_VALUES.md;

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.beacon.midnight,
    minHeight: '100vh',
    height: 'min-content',
    margin: '0 auto',
    position: 'relative',
    display: 'flex',
    width: '100%',
    maxWidth: 1536,
    flexDirection: 'column',
    overflowX: 'clip',
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      flexGrow: 1,
      flexDirection: 'row-reverse',
      paddingTop: theme.spacing(13),
      paddingRight: theme.spacing(5),
      maxHeight: '100%',
      boxSizing: 'border-box',
      overflowY: 'clip',
    },
  },
  logoImage: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(4.5),
    zIndex: 99,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      left: theme.spacing(6),
      top: theme.spacing(6),
    },
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    maxHeight: 280,
    objectFit: 'cover',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      maxHeight: 555,
    },
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      borderTopLeftRadius: 123,
      maxHeight: 'none',
    },
  },
  backgroundImageContainer: {
    maxHeight: 280,
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      maxHeight: 'none',
    },
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      width: '40%',
    },
  },
  content: {
    margin: theme.spacing(4.5, 2),
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      margin: theme.spacing(4.5, 5),
    },
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      width: '60%',
      overflow: 'auto',
    },
  },
}));

/**
 * Layout component for each onboarding step.
 *
 * @param {object} props
 * @param {string} props.backgroundImage - background image path
 * @param {object} props.classesOverride - override for custom classes
 * @param {React.ReactNode} props.children - children
 * @returns {React.ReactElement}
 */
export const Layout = ({ backgroundImage, classesOverride, children }) => {
  const classes = useStyles({ classes: classesOverride });
  const { width } = useViewport();

  return (
    <Box className={classes.root}>
      <img
        src={width < TABLET_BREAKPOINT ? LightLogoSVG : DarkLogoSVG}
        className={classes.logoImage}
        alt=""
      />
      <Box className={classes.backgroundImageContainer}>
        <img src={backgroundImage} alt="" className={classes.backgroundImage} />
      </Box>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};
