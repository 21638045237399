import { getFormattedShortDateWithWeekday } from './dateTime';
import { DATE_TIME } from '../assets/dateFormats';

const moment = require('moment-timezone');

export const isReservationUpcoming = (reservation) => {
  return (
    !isReservationPast(reservation) &&
    reservation.status !== 'deletedSubmitted' &&
    reservation.status !== 'deletedConfirmed'
  );
};

export const isReservationPast = (reservation) => {
  const {
    realDateTime,
    venue: { timezoneName },
  } = reservation;
  const datetimeNow = moment().tz(timezoneName);
  const reservationRealDateTime = moment.tz(
    realDateTime,
    DATE_TIME,
    timezoneName
  );
  return datetimeNow.isAfter(reservationRealDateTime);
};

export const createReservationPayload = (draftReservation) => {
  return {
    userId: draftReservation.userId,
    venueId: draftReservation.venue.id,
    sevenRoomsVenueId: draftReservation.venue.sevenRoomsVenueId,
    slotOptionId: draftReservation.slotOptionId,
    slotName: draftReservation.slotName || '',
    date: draftReservation.date,
    time: draftReservation.timeDisplay,
    firstName: draftReservation.firstName,
    lastName: draftReservation.lastName,
    phone: draftReservation.phoneNumber.replaceAll(/[()\s-]/g, ''),
    guests: parseInt(draftReservation.guests),
    email: draftReservation.emailAddress,
    diets: draftReservation.diets.map((item) => item.name),
    allergies: draftReservation.allergies.map((item) => item.name),
    specialOccasions: draftReservation.specialOccasions.map(
      (item) => item.name
    ),
    notes: draftReservation.notes,
    bookingPolicy: draftReservation.bookingPolicy || '',
    paymentInstrumentToken: draftReservation.paymentInstrumentToken,
    paymentIntermediary: draftReservation.paymentIntermediary,
    paymentIntermediaryToken: draftReservation.paymentIntermediaryToken,
    paymentCorrelationId: draftReservation.paymentCorrelationId,
    paymentAccountReferenceId: draftReservation.paymentAccountReferenceId,
    paymentProfileReferenceId: draftReservation.paymentProfileReferenceId,
    paymentSubTotal: draftReservation.paymentSubTotal || 0,
    paymentGratuity: draftReservation.paymentGratuity || 0,
    paymentServiceCharge: draftReservation.paymentServiceCharge || 0,
    paymentTax: draftReservation.paymentTax || 0,
    paymentPolicy: draftReservation.paymentPolicy || '',
    paymentRule: draftReservation.paymentRule || '', // to determine if the card is with or without card
    isInsider: draftReservation.venue?.isInsider,
    isCardholderTable: draftReservation.isExclusive,
  };
};

export const updateReservationPayload = (draftReservation) => {
  return {
    id: draftReservation.id,
    userId: draftReservation.userId,
    venueId: draftReservation.venue.id,
    sevenRoomsVenueId: draftReservation.venue.sevenRoomsVenueId,
    slotOptionId: draftReservation.slotOptionId,
    slotName: draftReservation.slotName || '',
    date: draftReservation.date,
    time: draftReservation.timeDisplay,
    guests: parseInt(draftReservation.guests),
    diets: draftReservation.diets?.map((item) => item.name),
    allergies: draftReservation.allergies?.map((item) => item.name),
    sevenRoomsReservationId: draftReservation.sevenRoomsReservationId,
    specialOccasions: draftReservation.specialOccasions?.map(
      (item) => item.name
    ),
    notes: draftReservation.notes,
    bookingPolicy: draftReservation.bookingPolicy || '',
    paymentRule: draftReservation.paymentRule || '',
    isInsider: draftReservation.venue?.isInsider,
    isCardholderTable: draftReservation.isExclusive,
  };
};

export const convertUserAllergies = (userAllergies) => {
  return userAllergies.items?.map((item) => item.allergy);
};

export const convertUserDiets = (userDiets) => {
  return userDiets.items?.map((item) => item.diet);
};

export const convertUserReservationItemsForDraft = (
  airtableReservationItems,
  draftReservationItems
) => {
  const airtableItems = {};
  airtableReservationItems?.forEach((item) => {
    airtableItems[item.name] = item;
  });
  return draftReservationItems?.map((item) => ({
    id: airtableItems[item]?.id,
    name: airtableItems[item]?.name,
  }));
};

export const getFormattedDate = (reservation, type) => {
  if (isReservationPast(reservation) || type === 'Canceled') {
    return moment(reservation.realDateTime).format('MMM D, YYYY');
  } else if (!reservation.realDateTime) {
    return moment(reservation.date).format('ddd, MMM D');
  }
  return getFormattedShortDateWithWeekday(reservation?.realDateTime);
};

export const getScreenReaderFormattedDate = (reservation, type) => {
  if (isReservationPast(reservation) || type === 'Canceled') {
    return getFormattedDate(reservation, type);
  }
  return moment(reservation.realDateTime).format('dddd, MMMM Do');
};

export const getTypeFromReservation = (reservation) => {
  if (
    isReservationPast(reservation) &&
    reservation.status !== 'deletedSubmitted' &&
    reservation.status !== 'deletedConfirmed'
  ) {
    return 'Completed';
  }
  return getTypeFromStatus(reservation.status);
};

// Other Types are Fill or Canceled (from UpdateReservation)
export const getTypeFromStatus = (status) => {
  switch (status) {
    case 'updatedSubmitted':
    case 'updatedConfirmed':
      return 'Updated';
    case 'deletedSubmitted':
    case 'deletedConfirmed':
      return 'Canceled';
    case 'createdSubmitted':
    case 'createdConfirmed':
    default:
      return 'Confirmed';
  }
};
